<template>
	<div id="app" element-loading-text='拼命加载中' element-loading-background='rgba(70, 70, 70, 0.8)'  v-loading="loading">
		<template v-if="type=='docx'">
			<vue-office-docx :src="url" style="height: 100%;width: 100%;" @rendered="renderedHandler" @error="errorHandler" />
		</template>
		<template v-if="type=='xlsx'">
			<vue-office-excel :src="url" style="height: 100%;width: 100%;" @rendered="renderedHandler" @error="errorHandler" />
		</template>
		<template v-if="type=='pdf'">
			<vue-office-pdf  :src="url" style="height: 100%;width: 100%;" @rendered="renderedHandler" @error="errorHandler" />
		</template>
	</div>
</template>

<script>
	import VueOfficeDocx from '@vue-office/docx'
	import '@vue-office/docx/lib/index.css'
	import VueOfficeExcel from '@vue-office/excel'
	import '@vue-office/excel/lib/index.css'
	import VueOfficePdf from '@vue-office/pdf'
	export default {
		name: 'app',
		components: {
			VueOfficeDocx,
			VueOfficeExcel,
			VueOfficePdf
		},
		data() {
			return {
				type:'',//文档类型
				url: '',//文档url
				loading:false,
			}
		},
		mounted() {
			this.url= this.getQueryString('url')
			this.type= this.getQueryString('type')
			console.log('this.url',this.url);
			console.log('this.type',this.type);
			this.loading=true
		},
		methods: {
			getQueryString(name) {
			    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
			    var r = window.location.search.substr(1).match(reg);
			    if (r != null) {
			        return decodeURIComponent(r[2]);
			    }
			    return null;
			},
			renderedHandler() {
				console.log("渲染完成")
				this.loading=false
			},
			errorHandler() {
				console.log("渲染失败")
				this.$message.warning('渲染失败')
				this.loading=false
			}
		}
	}
</script>

<style>
	html,body{
		margin: 0;padding: 0;width: 100%;height: 100%;
	}
	#app {
		width: 100%;height: 100%;
	}
</style>